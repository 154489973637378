var exports = {};

exports = function defined() {
  for (var i = 0; i < arguments.length; i++) {
    if (typeof arguments[i] !== "undefined") {
      return arguments[i];
    }
  }
};

export default exports;